export const eTdahAdQuestions = [
  "É atento quando conversa com alguém.",
  "É afobado no trabalho.",
  "Necessita fazer listas de tudo o que tem para fazer para não se esquecer de nada.",
  "Sente-se chateado e infeliz.",
  "Quando tem de seguir instruções (receitas, montagem de móveis), segue passo a passo e em sequência, tal como lhe é apresentado.",
  "É desorganizado financeiramente.",
  "É solitário.",
  "Termina tudo o que começa.",
  "Explode com facilidade (é do tipo pavio curto).",
  "É detalhista e minucioso.",
  "Arruma encrenca e confusão facilmente.",
  "Mostra-se insensível à dor e ao perigo.",
  "Tem sono agitado, mexe-se na cama.",
  "É bem aceito por todos.",
  "Costuma se dar mal por falar as coisas sem pensar.",
  "É persistente e insistente diante de uma ideia.",
  "Acidenta-se com facilidade (cai, tropeça, esbarra em móveis).",
  "Tende a discordar das regras e normas.",
  "Dá impressão de que não sabe o que quer.",
  "Evita trabalhos longos, detalhados e complicados.",
  "Tem dificuldade para se adaptar às mudanças.",
  "A qualidade do trabalho é comprometida porque não presta atenção suficiente.",
  "Inicia uma atividade com entusiasmo e dificilmente chega ao fim, é do tipo fogo de palha.",
  "Evita as atividades que exijam esforço mental prolongado (p. ex., leitura, filmes).",
  "Perde a paciência com os familiares.",
  "É rebelde com as pessoas e as situações.",
  "Persiste quando quer alguma coisa.",
  "Tem tendência a sonhar acordado.",
  "Faz planos cuidadosamente, considera todos os passos do começo ao fim.",
  "Parece sonhar acordado.",
  "Faz seu trabalho rápido demais.",
  "É distraído com tudo.",
  "Dificilmente chega ao final de um projeto.",
  "Seu hábito de trabalho é confuso e desorganizado.",
  "Necessita estar em constante movimentação.",
  "Atrasa os pagamentos porque se esquece das datas de vencimento.",
  "Mostra-se apático e indiferente diante das situações.",
  "Tem fortes reações emocionais (p. ex., choro, explosões de raiva, bate portas, quebra objetos, etc.)",
  "É agressivo.",
  "Tem problemas com a lei e/ou com a justiça.",
  "É imprudente, arrisca sempre.",
  "É tolerante diante das situações.",
  "Tem dificuldade para permanecer sentado, quando isso se faz necessário.",
  "É conhecido pelos outros como desligado, parecendo viver no espaço.",
  "Seu jeito de ser é motivo de discussão em casa.",
  "Tira conclusões mesmo antes de conhecer os fatos.",
  "Necessita estar em situações mais perigosas e arriscadas.",
  "Tem dificuldade em aceitar a opinião dos outros.",
  "Faz as coisas devagar, apresenta um ritmo de trabalho lento.",
  "Distrai-se enquanto trabalha e outras pessoas conversam.",
  "A mente voa longe enquanto lê.",
  "Faz tudo o que dá em sua cabeça.",
  "Costuma vingar-se das pessoas, não engole sapo.",
  "Precisa ser lembrado dos compromissos diários.",
  "Vive isolado, evita as atividades de grupo.",
  "É mais desorganizado do que a maioria das pessoas.",
  "Não observa detalhes e minúcias.",
  "Persiste até o fim com os seus objetivos, mesmo que sejam difíceis de alcançar.",
  "Sabe aguardar a vez (p. ex., fila de banco, em consultórios, etc).",
  "Responde antes de ouvir a pergunta inteira.",
  "É criticado por seu jeito de ser.",
  "Intromete-se em assuntos que não lhe dizem respeito.",
  "Costuma criticar os outros.",
  "Tem memória ruim para guardar instruções, ordens recebidas ou para decorar o que é preciso.",
  "Planeja suas ações, respeitando cada etapa do processo.",
  "É impulsivo; age antes de pensar.",
  "Costuma se esquecer de datas, números de telefone, compromissos importantes, a não ser que os anote.",
  "Necessita de novidades e de variedades em sua vida.",
  "Tem dificuldade para processar as informações recebidas."
];

export const sensorialProfileQuestions = [
  "Saio ou mudo para outra parte de loja (por ex. produtos para banho, velas, perfumes)",
  "Acrescento temperos na minha comida",
  "Não sinto cheiros que outras pessoas sentem",
  "Gosto de estar próxima a pessoas usando perfume",
  "Só como comidas familiares",
  "Muitas comidas parecem sem sabor para mim (em outras palavras, parece sem graça ou não tem muito sabor)",
  "Não gosto de balas de sabor muito forte (por ex., canela forte ou azedas)",
  "Vou cheirar flores frescas quando as vejo",
  "Tenho medo de alturas",
  "Gosta da sensação de movimento (dançar, correr, por ex.)",
  "Evito elevadores e escadas rolantes porque não gosto de movimento",
  "Tropeço nas coisas",
  "Não gosto do movimento de andar de carro",
  "Escolho me envolver em atividades físicas",
  "Não me sinto muito seguro quando descendo ou subindo escadas (por ex., tropeço, perco o equilíbrio, seguro no corrimão)",
  "Fico tonto facilmente (por ex., após me curvar ou levantar muito rapidamente)",
  "Gosto de ir a lugares com iluminação brilhante e que são coloridos",
  "Mantenho as persianas fechadas durante o dia",
  "Gosto de usar roupa muito colorida",
  "Fico frustrado quando tento encontrar alguma coisa em uma gaveta cheia ou sala bagunçada",
  "Não vejo a rua, prédio, ou placas de salas quando tento ir a um lugar novo",
  "Imagens visuais que se movem rapidamente no cinema ou TV me incomodam",
  "Não noto quando pessoas entram na sala",
  "Escolho fazer compras em lojas menores porque me desoriento em lojas grandes",
  "Incomoda-me quando há muito movimento ao meu redor (por ex. shopping cheio, desfile, parquinho)",
  "Limito as distrações enquanto trabalho (por ex., fecho a porta ou desligo a TV)",
  "Não gosto que me esfreguem as costas",
  "Gosto da sensação quando corto o cabelo",
  "Evito ou uso luvas em atividades que vão sujar as minhas mãos",
  "Toco outros enquanto falo (por ex., ponho minha mão em seus ombros ou sacudo suas mãos)",
  "Incomoda-me o modo que sinto minha boca quando acordo",
  "Gosto de andar descalço",
  "Sinto-me mal usando alguns tecidos (por ex, lã, seda, veludo cotelê, etiquetas em roupas)",
  "Não gosto de certas texturas de alimentos (por ex., pêssegos com casca, purê de maçã, queijo cottage, pasta de amendoin crocante)",
  "Afasto-me quando chegam muito perto de mim",
  "Não pareço notar quando meu rosto e mãos estão sujos",
  "Arranho-me ou tenho marcas roxas mas não me lembro como os fiz",
  "Evito ficar em filas ou ficar próximo a outras pessoas porque não gosto de ficar próximo demais dos outros",
  "Não pareço notar quando alguém toca meu braço ou costas",
  "Trabalho em duas ou mais tarefas ao mesmo tempo",
  "Levo mais tempo que outras pessoas para acordar de manhã",
  "Faço as coisas de improviso (em outras palavras, faço coisas sem planejar antes)",
  "Acho tempo para me afastar da minha vida ocupada e passar tempo sozinho",
  "Pareço mais lenta que outros quando tento seguir uma atividade ou tarefa",
  "Não pego piadas tão rapidamente quanto outros",
  "Afasto-me de multidões",
  "Acho atividades para fazer em frente a outros (por ex., música, esportes, falar em público e responder perguntas em aula)",
  "Acho difícil me concentrar por todo o tempo em uma aula ou reunião longos",
  "Evito situações em que possam acontecer coisas inesperadas (por ex, ir a lugares não familiares ou estar perto de pessoas que não conheço)",
  "Cantarolo, assobio, canto ou faço outros barulhos",
  "Assusto-me facilmente com sons altos ou inesperados (por ex., aspirador de pó, cachorro latindo, telefone tocando)",
  "Tenho dificuldade em seguir o que as pessoas estão falando quando falam rapidamente ou sobre assuntos não familiares",
  "Saio da sala quando outros assistem TV ou peço a eles que desliguem",
  "Distraio-me se há muito barulho em volta",
  "Não noto quando meu nome é chamado",
  "Uso estratégias para abafar sons (por ex., fecho a porta, cubro os ouvidos, uso protetores de ouvido)",
  "Fico longe de ambientes barulhentos",
  "Gosto de ir a lugares com muita música",
  "Tenho de pedir a pessoas que repitas coisas",
  "Acho difícil trabalhar com barulho de fundo (por ex., ventilador, rádio)"
];