import styled from 'styled-components';
import { Container, Col, Button } from 'reactstrap';

export const StyledContainer = styled(Container)`
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

export const StyledCol = styled(Col)`
  padding: 40px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

export const Logo = styled.img`
  max-width: 200px;
  height: auto;
`;

export const CustomButton = styled(Button)`
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 8px;
  background-color: #f8a8a7;
  border-color: #f8a8a7;

  &:hover {
    background-color: #f38988;
    border-color: #f38988;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 168, 167, 0.5);
  }

  &:active {
    background-color: #e07e7d;
    border-color: #e07e7d;
  }
`;