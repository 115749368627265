import React, { useState, useEffect } from 'react';
import { Formik, Form as FormikForm } from 'formik';
import { Row, Button, Label, Progress, Input } from 'reactstrap';
import emailjs from '@emailjs/browser';
import CustomRadioGroup from '../../components/Inputs/CustomRadioGroup';
import { StyledContainer, StyledCol, Logo, CustomButton } from '../../components/CustomGeneric';
import { eTdahAdQuestions as questionLabels } from '../../utils/questions';

import logoDefault from '../../assets/img/logo/logo.png';

interface CategoryScore {
  name: string;
  score: number;
  maxScore: number;
}

interface FormValues {
  questions: Record<string, number>;
}

interface Messages {
  [key: number]: string;
}

const initialValues: FormValues = {
  questions: questionLabels.reduce((acc, label, index) => ({ ...acc, [`q${index + 1}`]: 0 }), {})
};

const ETdahAdTest: React.FC = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [incentiveMessage, setIncentiveMessage] = useState("Vamos começar, você consegue!");
  const [started, setStarted] = useState(false);
  const [responses, setResponses] = useState<FormValues | null>(null);
  const [name, setName] = useState<string>('');
  const [age, setAge] = useState<string>('');
  const [errors, setErrors] = useState<{ name?: string, age?: string }>({});

  const progressPercent = ((currentQuestionIndex) / questionLabels.length) * 100;
  const isLastQuestion = currentQuestionIndex === questionLabels.length - 1;
  const isFirstQuestion = currentQuestionIndex === 0;

  useEffect(() => {
    updateIncentiveMessage(currentQuestionIndex);
  }, [currentQuestionIndex]);

  const updateIncentiveMessage = (index: number) => {
    const totalQuestions = questionLabels.length;
    const progressPercentage = Math.floor((index / totalQuestions) * 100);
  
    if (index === 0) {
      setIncentiveMessage("Vamos começar, você consegue!");
    } else if (index === 1) {
      setIncentiveMessage("Ótimo começo, continue assim!");
    } else {
      const messages: Messages = {
        20: "Continue firme!",
        30: "Já? Mantendo o ritmo.",
        40: "Você está indo muito bem!",
        50: "Metade do caminho já! Excelente trabalho.",
        60: "Siga em frente, estamos próximos!",
        70: "Você está cada vez mais perto.",
        80: "Uau, quase lá!",
        90: "Pertinho, finalize com chave de ouro!"
      };
  
      if (messages[progressPercentage]) {
        setIncentiveMessage(messages[progressPercentage]);
      }
    }
  
    if (index === questionLabels.length - 1) {
      setIncentiveMessage("Essa é a última pergunta, juro.");
    }
  };

  const calculateScores = (results: FormValues): CategoryScore[] => {
    const scores = {
      Desatenção: [6, 19, 20, 22, 23, 24, 28, 30, 32, 33, 34, 36, 37, 44, 49, 50, 51, 54, 56, 57, 64, 67, 69],
      Impulsividade: [9, 11, 12, 15, 18, 25, 26, 38, 39, 40, 41, 45, 46, 47, 48, 52, 53, 60, 61, 62, 63, 66, 68],
      "Aspectos Emocionais": [4, 7, 21, 55],
      "AAMA": [1, 5, 8, 10, 14, 16, 27, 29, 42, 58, 59, 65],
      Hiperatividade: [2, 3, 13, 17, 31, 35, 43]
    };

    const categoryScores: CategoryScore[] = [];

    for (const [key, questions] of Object.entries(scores)) {
      let sum = 0;
      let maxScore = questions.length * 5;
      questions.forEach(q => {
        const score = results.questions[`q${q}`];
        if (key === "AAMA") {
          sum += 5 - score;
        } else {
          sum += score;
        }
      });
      categoryScores.push({ name: key, score: sum, maxScore: maxScore - sum });
    }

    return categoryScores;
  };

  const handleFinish = (values: FormValues) => {
    setResponses(values);
    const scores = calculateScores(values);

    // Generate the results content to send
    const resultsContent = scores.map(score => `
      <p><strong>${score.name}:</strong> ${score.score} pontos</p>
    `).join('');

    sendEmail(name, age, resultsContent);
  };

  emailjs.init(process.env.REACT_APP_EMAILJS_USER_ID || '');

  const sendEmail = (name: string, age: string, results: string) => {
    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID || '',
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID || '',
      {
        from_name: name,
        name: name,
        age: age,
        results: results,
        reply_to: process.env.REACT_APP_EMAILJS_REPLY_TO || '',
      }
    )
    .then((result) => {
      console.log(result.text);
    }, (error) => {
      console.log(error.text);
    });
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const progressStyle = {
    width: '100%', 
    height: '5px', 
    backgroundColor: 'transparent', 
    backgroundImage: `linear-gradient(to right, red, green ${progressPercent}%, transparent ${progressPercent}%)`,
    backgroundSize: `${progressPercent}% 100%`,
    backgroundRepeat: 'no-repeat',
    transition: 'background-size 0.5s ease'
  };

  const validateFields = () => {
    const newErrors: { name?: string, age?: string } = {};
    if (!name) newErrors.name = 'Nome é obrigatório';
    if (!age) newErrors.age = 'Idade é obrigatória';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const handleStart = () => {
    if (validateFields()) {
      setStarted(true);
    }
  };  

  if (!started && !responses) {
    return (
      <StyledContainer className="bg-secondary" fluid>
        <Row className="justify-content-center ml-2 mr-2">
          <StyledCol md={8} lg={8}>
            <div className="text-center">
              <Logo src={logoDefault} />
            </div>
            <h3 className="text-center mb-4">Teste de Avaliação Comportamental ETDAH-AD</h3>
            <div className="d-flex">
              <p className="lh-sm" style={{ textAlign: 'justify' }}>
                A seguir, estarão alguns termos descritivos de comportamentos que você poderá apresentar. Considere a ocorrência dos comportamentos no momento atual e nos últimos seis meses. Leia cada item cuidadosamente.
              </p>
            </div>
            <div className="mb-4 d-flex">
              <p className="lh-sm" style={{ textAlign: 'justify' }}>
                Para responder, arraste clique, ou toque a barra até a opção que mais se adequar à sua opinião, indicando o grau em que o comportamento ocorre.
              </p>
            </div>
            <div className="mb-4">
              <Label for="name">Nome</Label>
              <Input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              {errors.name && <div className="text-danger">{errors.name}</div>}
            </div>
            <div className="mb-4">
              <Label for="age">Idade</Label>
              <Input
                type="number"
                id="age"
                value={age}
                onChange={(e) => setAge(e.target.value)}
                required
              />
              {errors.age && <div className="text-danger">{errors.age}</div>}
            </div>
            <div className="d-flex justify-content-center justify-content-sm-center">
              <CustomButton color="primary" onClick={handleStart} className="mr-2" size="md">
                Iniciar
              </CustomButton>
            </div>
          </StyledCol>
        </Row>
      </StyledContainer>
    );
  }   

  if (started && responses) {
    return (
      <StyledContainer className="bg-secondary" fluid>
        <Row className="justify-content-center ml-2 mr-2">
          <StyledCol md={8} lg={8}>
            <h3 className="text-center mb-4">Você concluiu a avaliação! 🎉🥳</h3>
            <div className="d-flex justify-content-center mb-4">
              Obrigada pela sua participação! Recebi as informações e irei analisar cuidadosamente.
            </div>
          </StyledCol>
        </Row>
      </StyledContainer>
    );
  }  

  return (
    <StyledContainer className="bg-secondary" fluid>
      <Row className="justify-content-center ml-2 mr-2">
        <StyledCol md={8} lg={8}>
          <h3 className="text-center mb-4">Teste de Avaliação Comportamental ETDAH-AD</h3>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              if (isLastQuestion) {
                handleFinish(values);
              } else {
                setCurrentQuestionIndex(currentQuestionIndex + 1);
              }
            }}
            enableReinitialize
          >
            {({ values, setFieldValue, handleSubmit }) => (
              <FormikForm onSubmit={handleSubmit}>
                <div className="mb-4">
                  <Label htmlFor={`q${currentQuestionIndex + 1}`} className="d-block mb-2">
                    <h5>Para a afirmação <strong><i>"{questionLabels[currentQuestionIndex]}"</i></strong>, a opção mais apropriada é:</h5>
                  </Label>
                  <div className="mt-6">
                    <CustomRadioGroup
                      value={values.questions[`q${currentQuestionIndex + 1}`]}
                      onChange={value => setFieldValue(`questions.q${currentQuestionIndex + 1}`, value)}
                    />
                  </div>
                  <div className="d-flex justify-content-end justify-content-sm-center mt-4">
                    <Progress bar style={progressStyle} value="100" max="100" />
                  </div>
                </div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                  <span className="text-muted small flex-shrink-0 mr-3">{incentiveMessage}</span>
                </div>
                <div className="d-flex justify-content-end justify-content-sm-center">
                  <Button color="warning" onClick={() => handlePrevious()} disabled={isFirstQuestion} className="mr-2">
                    Anterior
                  </Button>
                  <Button type="submit" color="success" className="ml-2">
                    {currentQuestionIndex === questionLabels.length - 1 ? 'Concluir' : 'Próxima'}
                  </Button>
                </div>
              </FormikForm>
            )}
          </Formik>
        </StyledCol>
      </Row>
    </StyledContainer>
  );
};

export default ETdahAdTest;