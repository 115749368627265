import React from 'react';
import styled from 'styled-components';
import { FormGroup, Label, Input } from 'reactstrap';

interface CustomRadioGroupProps {
  value: number;
  onChange: (value: number) => void;
  optionsSet?: 'default' | 'alternative';
}

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const OptionLabel = styled(Label)`
  margin-bottom: 10px;
`;

const CustomRadioGroup: React.FC<CustomRadioGroupProps> = ({ value, onChange, optionsSet = 'default' }) => {
  const defaultOptions = [
    { label: 'Nunca', value: 0 },
    { label: 'Muito raramente', value: 1 },
    { label: 'Raramente', value: 2 },
    { label: 'Geralmente', value: 3 },
    { label: 'Frequentemente', value: 4 },
    { label: 'Muito frequentemente', value: 5 },
  ];

  const alternativeOptions = [
    { label: 'Quase nunca', value: 1 },
    { label: 'Raramente', value: 2 },
    { label: 'Ocasionalmente', value: 3 },
    { label: 'Frequentemente', value: 4 },
    { label: 'Quase sempre', value: 5 },
  ];

  const options = optionsSet === 'default' ? defaultOptions : alternativeOptions;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(Number(e.target.value));
  };

  return (
    <FormGroup>
      <OptionsContainer>
        {options.map(option => (
          <OptionLabel key={option.value}>
            <Input
              type="radio"
              name="custom-slider"
              value={option.value}
              checked={value === option.value}
              onChange={handleChange}
            />
            {option.label}
          </OptionLabel>
        ))}
      </OptionsContainer>
    </FormGroup>
  );
};

export default CustomRadioGroup;