import React, { useState, useEffect } from 'react';
import { Formik, Form as FormikForm } from 'formik';
import { Row, Button, Label, Progress, Input } from 'reactstrap';
import emailjs from '@emailjs/browser';
import CustomRadioGroup from '../../components/Inputs/CustomRadioGroup';
import { StyledContainer, StyledCol, Logo, CustomButton } from '../../components/CustomGeneric';
import { sensorialProfileQuestions as questionLabels } from '../../utils/questions';

import logoDefault from '../../assets/img/logo/logo.png';

interface CategoryScore {
  name: string;
  score: number;
  maxScore: number;
}

interface FormValues {
  questions: Record<string, number>;
  comments: Record<string, string>;
}

interface Messages {
  [key: number]: string;
}

interface QuadrantRanges {
  [key: string]: number[];
}

interface AgeGroupRanges {
  1: QuadrantRanges;
  2: QuadrantRanges;
  3: QuadrantRanges;
  4: QuadrantRanges;
}

interface AgeGroups {
  "11-17": AgeGroupRanges;
  "18-64": AgeGroupRanges;
  "65+": AgeGroupRanges;
}

const initialValues: FormValues = {
  questions: questionLabels.reduce((acc, label, index) => ({ ...acc, [`q${index + 1}`]: 0 }), {}),
  comments: {
    group1: '',
    group2: '',
    group3: '',
    group4: '',
    group5: '',
    group6: '',
    additional: ''
  }
};

const ageGroups: AgeGroups = {
  "11-17": {
    1: { "Muito menos que a maioria das pessoas": [15, 18], "Menos que a maioria das pessoas": [19, 26], "Semelhante à maioria das pessoas": [27, 40], "Mais que a maioria das pessoas": [41, 51], "Muito mais que a maioria das pessoas": [52, 75] },
    2: { "Muito menos que a maioria das pessoas": [15, 27], "Menos que a maioria das pessoas": [28, 41], "Semelhante à maioria das pessoas": [42, 58], "Mais que a maioria das pessoas": [59, 65], "Muito mais que a maioria das pessoas": [66, 75] },
    3: { "Muito menos que a maioria das pessoas": [15, 20], "Menos que a maioria das pessoas": [21, 25], "Semelhante à maioria das pessoas": [26, 40], "Mais que a maioria das pessoas": [41, 48], "Muito mais que a maioria das pessoas": [49, 75] },
    4: { "Muito menos que a maioria das pessoas": [15, 19], "Menos que a maioria das pessoas": [20, 25], "Semelhante à maioria das pessoas": [26, 40], "Mais que a maioria das pessoas": [41, 48], "Muito mais que a maioria das pessoas": [49, 75] },
  },
  "18-64": {
    1: { "Muito menos que a maioria das pessoas": [15, 18], "Menos que a maioria das pessoas": [19, 23], "Semelhante à maioria das pessoas": [24, 35], "Mais que a maioria das pessoas": [36, 44], "Muito mais que a maioria das pessoas": [45, 75] },
    2: { "Muito menos que a maioria das pessoas": [15, 35], "Menos que a maioria das pessoas": [36, 42], "Semelhante à maioria das pessoas": [43, 56], "Mais que a maioria das pessoas": [57, 62], "Muito mais que a maioria das pessoas": [63, 75] },
    3: { "Muito menos que a maioria das pessoas": [15, 19], "Menos que a maioria das pessoas": [20, 25], "Semelhante à maioria das pessoas": [26, 41], "Mais que a maioria das pessoas": [42, 48], "Muito mais que a maioria das pessoas": [49, 75] },
    4: { "Muito menos que a maioria das pessoas": [15, 19], "Menos que a maioria das pessoas": [20, 26], "Semelhante à maioria das pessoas": [27, 41], "Mais que a maioria das pessoas": [42, 49], "Muito mais que a maioria das pessoas": [50, 75] },
  },
  "65+": {
    1: { "Muito menos que a maioria das pessoas": [15, 19], "Menos que a maioria das pessoas": [20, 26], "Semelhante à maioria das pessoas": [27, 40], "Mais que a maioria das pessoas": [41, 51], "Muito mais que a maioria das pessoas": [52, 75] },
    2: { "Muito menos que a maioria das pessoas": [15, 28], "Menos que a maioria das pessoas": [29, 42], "Semelhante à maioria das pessoas": [43, 52], "Mais que a maioria das pessoas": [53, 63], "Muito mais que a maioria das pessoas": [64, 75] },
    3: { "Muito menos que a maioria das pessoas": [15, 18], "Menos que a maioria das pessoas": [19, 25], "Semelhante à maioria das pessoas": [26, 41], "Mais que a maioria das pessoas": [42, 48], "Muito mais que a maioria das pessoas": [49, 75] },
    4: { "Muito menos que a maioria das pessoas": [15, 18], "Menos que a maioria das pessoas": [19, 25], "Semelhante à maioria das pessoas": [26, 42], "Mais que a maioria das pessoas": [43, 49], "Muito mais que a maioria das pessoas": [50, 75] },
  }
}

const SensorialProfile: React.FC = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [incentiveMessage, setIncentiveMessage] = useState("Vamos começar, você consegue!");
  const [started, setStarted] = useState(false);
  const [responses, setResponses] = useState<FormValues | null>(null);
  const [name, setName] = useState<string>('');
  const [age, setAge] = useState<string>('');
  const [errors, setErrors] = useState<{ name?: string, age?: string }>({});

  const progressPercent = ((currentQuestionIndex) / questionLabels.length) * 100;
  const isLastQuestion = currentQuestionIndex === questionLabels.length - 1;
  const isFirstQuestion = currentQuestionIndex === 0;

  useEffect(() => {
    updateIncentiveMessage(currentQuestionIndex);
  }, [currentQuestionIndex]);

  const updateIncentiveMessage = (index: number) => {
    const totalQuestions = questionLabels.length;
    const progressPercentage = Math.floor((index / totalQuestions) * 100);
  
    if (index === 0) {
      setIncentiveMessage("Vamos começar, você consegue!");
    } else if (index === 1) {
      setIncentiveMessage("Ótimo começo, continue assim!");
    } else {
      const messages: Messages = {
        20: "Continue firme!",
        30: "Já? Mantendo o ritmo.",
        40: "Você está indo muito bem!",
        50: "Metade do caminho já! Excelente trabalho.",
        60: "Siga em frente, estamos próximos!",
        70: "Você está cada vez mais perto.",
        80: "Uau, quase lá!",
        90: "Pertinho, finalize com chave de ouro!"
      };
  
      if (messages[progressPercentage]) {
        setIncentiveMessage(messages[progressPercentage]);
      }
    }
  
    if (index === questionLabels.length - 1) {
      setIncentiveMessage("Essa é a última pergunta, juro.");
    }
  };

  const calculateScores = (results: FormValues): CategoryScore[] => {
    const scores = {
      Quadrante1: [3, 6, 12, 15, 21, 23, 36, 37, 39, 41, 44, 45, 52, 55, 59],
      Quadrante2: [2, 4, 8, 10, 14, 17, 19, 28, 30, 32, 40, 42, 47, 50, 58],
      Quadrante3: [7, 9, 13, 16, 20, 22, 25, 27, 31, 33, 34, 48, 51, 54, 60],
      Quadrante4: [1, 5, 11, 18, 24, 26, 29, 35, 38, 43, 46, 49, 53, 56, 57]
    };

    const categoryScores: CategoryScore[] = [];

    for (const [key, questions] of Object.entries(scores)) {
      let sum = 0;
      let maxScore = questions.length * 5;
      questions.forEach(q => {
        sum += results.questions[`q${q}`];
      });
      categoryScores.push({ name: key, score: sum, maxScore: maxScore - sum });
    }

    return categoryScores;
  };

  const interpretScore = (score: number, age: number, quadrant: keyof AgeGroupRanges): string => {
    let ageGroup: keyof AgeGroups;
    if (age >= 11 && age <= 17) ageGroup = "11-17";
    else if (age >= 18 && age <= 64) ageGroup = "18-64";
    else ageGroup = "65+";
  
    const quadrantRanges = ageGroups[ageGroup][quadrant];
    for (const [interpretation, range] of Object.entries(quadrantRanges)) {
      if (score >= range[0] && score <= range[1]) {
        return interpretation;
      }
    }
    return "Sem interpretação disponível";
  };
  
  const handleFinish = (values: FormValues) => {
    setResponses(values);
    const scores = calculateScores(values);
  
    const resultsContent = scores.map((score, index) => `
      <p><strong>Quadrante ${index + 1} (${score.name}):</strong> ${score.score} pontos - ${interpretScore(score.score, parseInt(age), (index + 1) as keyof AgeGroupRanges)}</p>
    `).join('');
  
    const commentsContent = `
      <p><strong>Comentários para o grupo de respostas 1:</strong> ${values.comments.group1}</p>
      <p><strong>Comentários para o grupo de respostas 2:</strong> ${values.comments.group2}</p>
      <p><strong>Comentários para o grupo de respostas 3:</strong> ${values.comments.group3}</p>
      <p><strong>Comentários para o grupo de respostas 4:</strong> ${values.comments.group4}</p>
      <p><strong>Comentários para o grupo de respostas 5:</strong> ${values.comments.group5}</p>
      <p><strong>Comentários para o grupo de respostas 6:</strong> ${values.comments.group6}</p>
      <p><strong>Comentários adicionais:</strong> ${values.comments.additional}</p>
    `;
  
    sendEmail(name, age, resultsContent + commentsContent);
  };

  emailjs.init(process.env.REACT_APP_EMAILJS_USER_ID || '');
  const sendEmail = (name: string, age: string, results: string) => {
    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID || '',
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID || '',
      {
        from_name: name,
        name: name,
        age: age,
        results: results,
        reply_to: process.env.REACT_APP_EMAILJS_REPLY_TO || '',
      }
    )
    .then((result) => {
      console.log(result.text);
    }, (error) => {
      console.log(error.text);
    });
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const progressStyle = {
    width: '100%', 
    height: '5px', 
    backgroundColor: 'transparent', 
    backgroundImage: `linear-gradient(to right, red, green ${progressPercent}%, transparent ${progressPercent}%)`,
    backgroundSize: `${progressPercent}% 100%`,
    backgroundRepeat: 'no-repeat',
    transition: 'background-size 0.5s ease'
  };

  const validateFields = () => {
    const newErrors: { name?: string, age?: string } = {};
    if (!name) newErrors.name = 'Nome é obrigatório';
    if (!age) newErrors.age = 'Idade é obrigatória';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const handleStart = () => {
    if (validateFields()) {
      setStarted(true);
    }
  };  

  if (!started && !responses) {
    return (
      <StyledContainer className="bg-secondary" fluid>
        <Row className="justify-content-center ml-2 mr-2">
          <StyledCol md={8} lg={8}>
            <div className="text-center">
              <Logo src={logoDefault} />
            </div>
            <h3 className="text-center mb-4">Perfil Sensorial</h3>
            <div className="d-flex">
              <p className="lh-sm" style={{ textAlign: 'justify' }}>
              Por favor, marque a opção que melhor descreve a frequência com que você desempenha os 
              os comportamentos listados. Se você não conseguir comentar, pois nunca vivenciou 
              uma situação como a apresentada, marque a opção "Nunca".
              Escreva comentários no final de cada sessão.
              </p>
            </div>
            <div className="mb-4">
              <Label for="name">Nome</Label>
              <Input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              {errors.name && <div className="text-danger">{errors.name}</div>}
            </div>
            <div className="mb-4">
              <Label for="age">Idade</Label>
              <Input
                type="number"
                id="age"
                value={age}
                onChange={(e) => setAge(e.target.value)}
                required
              />
              {errors.age && <div className="text-danger">{errors.age}</div>}
            </div>
            <div className="d-flex justify-content-center justify-content-sm-center">
              <CustomButton color="primary" onClick={handleStart} className="mr-2" size="md">
                Iniciar
              </CustomButton>
            </div>
          </StyledCol>
        </Row>
      </StyledContainer>
    );
  }   

  if (started && responses) {
    return (
      <StyledContainer className="bg-secondary" fluid>
        <Row className="justify-content-center ml-2 mr-2">
          <StyledCol md={8} lg={8}>
            <h3 className="text-center mb-4">Você concluiu a avaliação! 🎉🥳</h3>
            <div className="d-flex justify-content-center mb-4">
              Obrigada pela sua participação! Recebi as informações e irei analisar cuidadosamente.
            </div>
          </StyledCol>
        </Row>
      </StyledContainer>
    );
  }  

  return (
    <StyledContainer className="bg-secondary" fluid>
      <Row className="justify-content-center ml-2 mr-2">
        <StyledCol md={8} lg={8}>
          <h3 className="text-center mb-4">Perfil Sensorial</h3>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              if (isLastQuestion) {
                handleFinish(values);
              } else {
                setCurrentQuestionIndex(currentQuestionIndex + 1);
              }
            }}
            enableReinitialize
          >
            {({ values, setFieldValue, handleSubmit }) => (
              <FormikForm onSubmit={handleSubmit}>
                <div className="mb-4">
                  <Label htmlFor={`q${currentQuestionIndex + 1}`} className="d-block mb-2">
                    <h5>Para a afirmação <strong><i>"{questionLabels[currentQuestionIndex]}"</i></strong>, a opção mais apropriada é:</h5>
                  </Label>
                  <div className="mt-6">
                    <CustomRadioGroup
                      value={values.questions[`q${currentQuestionIndex + 1}`]}
                      onChange={value => setFieldValue(`questions.q${currentQuestionIndex + 1}`, value)}
                      optionsSet="alternative"
                    />
                  </div>
                  <div className="d-flex justify-content-end justify-content-sm-center mt-4">
                    <Progress bar style={progressStyle} value="100" max="100" />
                  </div>
                </div>
                {currentQuestionIndex === 8 && (
                  <div className="mb-4">
                    <Label htmlFor="group1-comments">Deseja adicionar algum comentário sobre o primeiro grupo de perguntas? Se sim, faça-o abaixo.</Label>
                    <Input
                      type="textarea"
                      id="group1-comments"
                      value={values.comments.group1}
                      onChange={(e) => setFieldValue('comments.group1', e.target.value)}
                    />
                  </div>
                )}
                {currentQuestionIndex === 16 && (
                  <div className="mb-4">
                    <Label htmlFor="group1-comments">Deseja adicionar algum comentário sobre o segundo grupo de perguntas? Se sim, faça-o abaixo.</Label>
                    <Input
                      type="textarea"
                      id="group2-comments"
                      value={values.comments.group2}
                      onChange={(e) => setFieldValue('comments.group2', e.target.value)}
                    />
                  </div>
                )}
                {currentQuestionIndex === 26 && (
                  <div className="mb-4">
                    <Label htmlFor="group1-comments">Deseja adicionar algum comentário sobre o terceiro grupo de perguntas? Se sim, faça-o abaixo.</Label>
                    <Input
                      type="textarea"
                      id="group3-comments"
                      value={values.comments.group3}
                      onChange={(e) => setFieldValue('comments.group3', e.target.value)}
                    />
                  </div>
                )}
                {currentQuestionIndex === 39 && (
                  <div className="mb-4">
                    <Label htmlFor="group1-comments">Deseja adicionar algum comentário sobre o quarto grupo de perguntas? Se sim, faça-o abaixo.</Label>
                    <Input
                      type="textarea"
                      id="group4-comments"
                      value={values.comments.group4}
                      onChange={(e) => setFieldValue('comments.group4', e.target.value)}
                    />
                  </div>
                )}
                {currentQuestionIndex === 49 && (
                  <div className="mb-4">
                    <Label htmlFor="group1-comments">Deseja adicionar algum comentário sobre o quinto grupo de perguntas? Se sim, faça-o abaixo.</Label>
                    <Input
                      type="textarea"
                      id="group5-comments"
                      value={values.comments.group5}
                      onChange={(e) => setFieldValue('comments.group5', e.target.value)}
                    />
                  </div>
                )}
                {currentQuestionIndex === 59 && (
                  <div className="mb-4">
                    <Label htmlFor="group1-comments">Deseja adicionar algum comentário sobre o sexto grupo de perguntas? Se sim, faça-o abaixo.</Label>
                    <Input
                      type="textarea"
                      id="group6-comments"
                      value={values.comments.group6}
                      onChange={(e) => setFieldValue('comments.group6', e.target.value)}
                    />
                  </div>
                )}
                {isLastQuestion && (
                  <div className="mb-4">
                    <Label htmlFor="additional-comments">Existem aspectos da vida diária que não são satisfatórios para você? Em caso afirmativo, por favor, explique:</Label>
                    <Input
                      type="textarea"
                      id="additional-comments"
                      value={values.comments.additional}
                      onChange={(e) => setFieldValue('comments.additional', e.target.value)}
                    />
                  </div>
                )}
                <div className="mb-4 d-flex align-items-center justify-content-center">
                  <span className="text-muted small flex-shrink-0 mr-3">{incentiveMessage}</span>
                </div>
                <div className="d-flex justify-content-end justify-content-sm-center">
                  <Button color="warning" onClick={() => handlePrevious()} disabled={isFirstQuestion} className="mr-2">
                    Anterior
                  </Button>
                  <Button type="submit" color="success" className="ml-2">
                    {isLastQuestion ? 'Concluir' : 'Próxima'}
                  </Button>
                </div>
              </FormikForm>
            )}
          </Formik>
        </StyledCol>
      </Row>
    </StyledContainer>
  );
};

export default SensorialProfile;