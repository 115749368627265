import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import ETdahAdTest from './views/edTDAHView';
import SensorialProfile from './views/SensorialProfileView';
import GlobalStyles from './GlobalStyles';
import './assets/css/argon-design-system-react.css';

function App() {
  return (
    <Router>
      <GlobalStyles />
      <Routes>
        <Route path="/testes/etdah-test" element={<ETdahAdTest />} />
        <Route path="/testes/perfil-sensorial" element={<SensorialProfile />} />
      </Routes>
    </Router>
  );
}

export default App;